.home_btn {
    background-color: #2C2E35;
    padding: 10px 0;
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
}
.home_btn:hover
{
    background-color: #c09b58;
    color: #fff;

}
.calc_buttons li:last-child {
    margin-bottom: 0;
}

.form_back {
    background-color: #c09b58;
    padding: 10px 15px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    font-weight: bold;

}

.radio_btns label, .checkboxes label {
    padding: 0 5px;
    margin-right: 10px;
}

.formLabel {
    text-align: left;
    width: 100%;
}

.line_break {
    margin: 40px 0 0 0;
    padding: 0 0 40px 0;
    border-top: 1px solid #e6e6e6;
    display: block;
}

.table {
    text-align: left;
}

.quote_big {
    font-size: 60px;
    line-height: 60px;
    font-weight: bold;
}

.quote_subtext {
    font-size: 12px;
    line-height: 12px;
}

.calc_form {
    text-align: left;
}

.quote_table {
    margin-top: 20px;
}

.container-1{
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    max-width: 900px;
    margin: auto !important;
}

.input-group-text{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.cursor-pointer{
    cursor: pointer;
}

.row{
    margin: 0px !important;
    align-items: center;
}

.bg-1{
    background-color: #c09b58;
    color: #fff;
    text-transform: uppercase;
}
.bg-1 tr th{
    padding: 20px 9px !important;
}
.table-success{
    font-weight: bold;
}
.page-title{
    width: 100%;
    padding: 10px;
    background: #c09b58;
    color: #fff;
    margin-bottom: 10px !important;
}
.page-title h2{
    margin-left: 5px !important;
    text-transform: uppercase;
}
.form-check-input:checked{
    background-color: #c09b58;
    border-color: #c09b58;
}
.check{
    display: inline-flex;
}

.btn-outline-secondary {
    background-color: #c09b58 !important;
    color: #fff !important;
}

.form-control{
    appearance: auto;
}
.form-control:focus{
    box-shadow: none !important;
    border-color: #2C2E35 !important;
    border: 1px solid #000 !important;
}

/* .btn
{
    color: #fff; 
} */

.details{
    width: 95%;
    margin: 0px 2.5% 50px 2.5% !important;
    border: 1px solid #f1f1f1;
}
.details-heading{
    border: 1px solid #f1f1f1;
    padding: 10px !important;
}
.details-body{
    border: 1px solid #f1f1f1;
    display: flex !important;
    width: 100%;
}
.details-body-content{
    border-right: 1px solid #f1f1f1;
    width: 50%;
    text-align: center;
    padding: 10px;
}

@media screen and (max-width:800px) {
    .details-body{
        flex-direction: column;
    }
    .details-body-content{
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
        text-align: center;
    }
}

table.margin-top {
    margin-top: 50px;
}
.address-lookup-label {
	display: none;
}
button.next {
  float: right;
  width: auto;
}
.privacy_toggle {
    text-decoration: underline;
    color: #C09B58;
    cursor: pointer;
}
a {
    color: #C09B58;
}
a:hover {
    color: #000;
}
.address-lookup-wrapper {
	width: 100%;
}
.address-lookup-input {
	width: 80%;
	color: #212529;
	border-radius: 5px;
	padding: 0.375rem 0.75rem;
	border: 1px solid #ced4da;
}
.address-lookup-search-button {
	width: 18%;
	margin-left: 2%;
	background: #C09B58;
	border: none;
	color: #fff;
	padding: 0.375rem 0.75rem;
	border-radius: 5px;
	border: 1px solid #C09B58;
}
.address-lookup-select {
	width: 100%;
	color: #212529;
	border-radius: 5px;
	padding: 0.375rem 0.75rem;
	border: 1px solid #ced4da;
	margin-top: 10px;
}
.quote_actions {
	display: flex;
	margin: 50px 0;
}
.print_icon {
	font-size: 20px;
	background-color: #C09B58;
	color: #fff;
	border-radius: 5px;
	padding: 0 10px !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.top_logo {
    max-width: 20%;
    margin: 0 0 50px 0;
}
@media screen and (max-width:767px) {
    .media-1{
        flex-direction: column;
    }
    .media-width{
        width: 100%;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .about-1{
        margin-bottom: 15px;
    }
    .text-end {
        text-align: left;
      }
      .address-lookup-search-button{
        width: 28%;
      }
      .address-lookup-input{
        width: 70%;
      }
    .check{
        display: flex;
    }
    .checkbox-1{
        width: 50%;
        display: inline-flex;
    }
}

/* Portal CSS */
@font-face {
    font-family: body-font;
    src: url(../font/Raleway-Regular.ttf);
}

body {
    padding: 0px;
    margin: 0px;
    font-family: body-font !important;
}
p{
    margin-bottom: 0 !important;
}
.login_form_page {
    background: #F4F5F7;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login_form {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    text-align: left;
}

.label {
    margin: 5px 0px;
}

#email:focus,
#password:focus,
#forget_email:focus {
    border-color: #c99d66 !important;
    box-shadow: none !important;
}

.hide_pass {
    margin-left: -30px;
    cursor: pointer;
}

.forget {
    font-size: 13px;
}

.forget a {
    text-decoration: none;
}

td {
    font-weight: 500;
}

.title {
    font-size: 25px;
    font-weight: 700;
}

.btn-1 {
    background-color: #2c2e35;
    color: #fff;
    border: none;
    padding: 10px 25px;
    border-radius: 10px;
    font-weight: 400;
}

.btn-1:hover {
    background-color: #c99d66;
}

/* index.hml */

.bg-1 {
    background-color: #fff;
}

.container-fluid {
    padding: 0 !important;
}

.profile {
    background-color: #2C2E35;
}
.logout_btn {
	background-color: #2C2E35;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	width: 100%;
	text-align: center;
	text-decoration: none !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.logout_btn:hover {
    color: #C09B58;
}
.profile_text {
	font-size: 14px;
	text-transform: none;
}
.dropdown-item {
    text-transform: none;
}
.h3{
    margin-bottom: 0 !important;
}
.gap{
    gap: 15px;
}
.profile_img{
    display: grid;
    place-items: center;
}
/* Profile */
.dropdown-item {
    font-size: 15px;
}

.col_1 {
    color: #2c2e35 !important;
}

.btn-2 {
    background-color: transparent !important;
    color: #2c2e35 !important;
    border: none !important;
    padding: 0;
}

.dropdown-menu {
	padding: 0 !important;
	border-radius: 5px !important;
	width: 200px !important;
	overflow: hidden;
	border: none;
	box-shadow: 0px 10px 20px #00000070;
}
.dropdown-toggle::after {
    display: none;
}

.btn-2.show {
    inset: 20px 0px auto auto !important;
}

header {
	box-shadow: 1px 1px 11px #00000030;
	height: auto;
	min-height: 5vh;
	z-index: 9;
	position: relative;
}

.content_section {
    min-height: 95vh;
    background: #F4F5F7;
}

.ic {
    margin-right: 15px;
}

.desk_nav ul#nav {
	list-style: none;
	text-align: center;
	padding: 0 10%;
	margin: 10px 0;
}

.desk_nav ul#nav li a,
.ic {
    text-decoration: none;
    color: #fff;
    font-size: 17px;

}

.desk_nav ul#nav li:hover a {
    color: #c99d66;
}

.desk_nav ul#nav li:hover .ic {
    color: #c99d66;
}

.desk_nav ul#nav li {
	padding: 20px 0px;
	display: flex;
	align-items: center;
}

.sidebar {
    width: 18vw;
    display: block;
    height: auto;
    min-height: 91.5vh;
    background-color: #2c2e35;
}

.chart {
    width: 48%;
    padding: 1%;
    margin: 1%;
    overflow: hidden;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
}

.offcanvas {
    background-color: #2c2e35 !important;
}

.btn-x {
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: #fff !important;
}

.btn-bar {
    background-color: transparent;
    border: none;
    text-decoration: none;
    font-size: 25px;
    color: #2c2e35 !important;
}

.d-center {
    display: flex;
    align-items: center;
    gap: 15%;
    justify-content: center;
}

.content {
	width: 98%;
	padding: 2%;
	height: auto;
	background-color: #fff;
	/* border-radius: 10px; */
	position: relative;
	margin: 20px;
	border: 1px solid #cccccc60;
}

.table {
    table-layout: fixed;
}

.chart_head {
    width: 100%;
    height: auto;
    padding: 20px 10px 10px 10px;
}

.chart_head h4 {
    font-weight: 700;
    color: #2c2e35;
    cursor: pointer;
}

.chart_body {
    padding: 10px;
}

.chart_head h4:hover {
    color: #c99d66;
}

.chart_footer {
    color: #2c2e35;
    text-align: center;
    padding: 10px;
}
.bg-2{
    background-color: #F4F5F7;
    color: #2c2e35;
    text-decoration: none;
}
.cen-1{
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-3 {
    background-color: transparent;
    border: none;
}

.btn-3 i {
    font-size: 18px;
}

.btn-bar {
    display: none;
}

@media only screen and (max-width: 992px) {
    content {
        width: 100% !important;
    }

    .btn-bar {
        display: block;
    }

    .d-center a img {
        width: 120px !important;
    }

    .col_1 {
        color: #2c2e35 !important;
    }
    .bg-1.p-3{
        padding: 10px 15px !important;
    }
}
.sidebar li a.active, .sidebar li a.active svg {
	color: #c99d66 !important;
}

/* table {
    border-spacing: 0;
    border: 1px solid #2C2E35;
    text-align: left;
    width: 100%;
    border-radius: 10px;
}
th {
    font-weight: bold;
}
table tr:last-child td {
    border-bottom: 0;
}

th, td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #2C2E35;
    border-right: 1px solid #2C2E35;
}
td:last-child {
    border-right: 0;
} */
td {
    word-wrap: anywhere;
}
.search_field {
	width: 50%;
}
.show_per_page {
    width: 50px;
}
.no-block {
    display: inline;
}

.btn-grey {
    background-color: #2C2E35;
    color: #fff;
}
.loading {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}
.sort_arrows {
	float: right;
    display: none;
}
th[title="Toggle SortBy"] .sort_arrows {
    display: block;
}

.half_width_table {
    width: 100%;
}
.half_width_table td{
    text-align: left;
}

.usersList {
    list-style: none;
}

.usersList li:first-child {
    border: none;
    padding: 0;
}

.usersList li {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}

.userHeading {
    font-weight: 700;
    font-size: 20px;
    padding: 20px 0 !important;
    width: 25%;
    text-align: left;
}

.usersList li div {
    width: 30%;
    text-align: left;
    padding: 0 2% 0 0;
}

.usersList li div:last-child {
    width: 10%;
    text-align: center;
    padding: 0;
}